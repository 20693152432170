import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';

import { dadosGlobaisContext } from './ContextGlobal';

import aguarde from './imagens/aguarde.gif';
import cancel from './imagens/cancel.png';
import search2 from './imagens/search2.png';

const useStyles = makeStyles((theme) => ({
    gridEmForm: {
        marginBottom: '20px',
        alignItems: 'center'
    },
    fonteMiniTextField: {
        fontSize: 10
    },
    fonteMenorTextField: {
        fontSize: 12
    },
    fonteMenorInputLabel: {
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

var wUnidadeLargura = 0;

function EscolheLocal(props) {
    const [dadosGlobais, dispatchDadosGlobais] = useContext(dadosGlobaisContext);

    const [selectedIdCep, setSelectedIdCep] = useState(dadosGlobais.idCep);
    const [texCep, setTexCep] = useState(dadosGlobais.cep);
    const [idCidadeCep, setIdCidadeCep] = useState(dadosGlobais.idCidadeCep);
    const [cidadeCep, setCidadeCep] = useState(dadosGlobais.cidadeCep);
    const [texLogradouroCep, setTexLogradouroCep] = useState(dadosGlobais.logradouro);

    const [dataCidades, setDataCidades] = useState([]);
    const [selectedIdCid, setSelectedIdCid] = useState(dadosGlobais.idCidadeLogra);
    const [texCidade, setTexCidade] = useState(dadosGlobais.cidadeLogra);
    const [popupCidAberto, setPopupCidAberto] = useState(false);
    const [descCidade, setDescCidade] = useState(null);

    const [dataLogradouros, setDataLogradouros] = useState([]);
    const [selectedIdLogr, setSelectedIdLogr] = useState(dadosGlobais.idLogradouro);
    const [texLogradouro, setTexLogradouro] = useState(dadosGlobais.logradouro);
    const [popupLogrAberto, setPopupLogrAberto] = useState(false);
    const [descLogradouro, setDescLogradouro] = useState(null);

    const [latitude, setLatitude] = useState(dadosGlobais.latLocal);
    const [longitude, setLongitude] = useState(dadosGlobais.lngLocal);

    const [dispAnimaAguardeCid, setDispAnimaAguardeCid] = useState(0);
    const [dispAnimaAguardeLogr, setDispAnimaAguardeLogr] = useState(0);
    const [dispAnimaAguardeCep, setDispAnimaAguardeCep] = useState(0);
    const [mensagem, setMensagem] = useState("");

    const classes = useStyles();

    async function buscaDadosCep() {
        //Keyboard.dismiss();

        if (texCep === "") {
            setMensagem('Digite um CEP!');
        }
        else {
            setDispAnimaAguardeCep(1);
            const requestOptions = { method: 'GET' };
            var wCtrlBuscaDados = 0;
            var wAuxDataCep = [];

            for (let i = 0; i <= 0; i++) {
                if (i == 0) {
                    const response = await fetch('https://www.iqueest.com.br/ws_iqueest/localizacao/cep?cep=' + texCep, requestOptions);
                    if (response.ok == true) {
                        wCtrlBuscaDados += 1;
                        wAuxDataCep = await response.json();
                    }
                    else {
                        setDispAnimaAguardeCep(0);

                        if (response.status == 418) {
                            window.alert('Dados informados com caracteres inválidos!\nRevise os dados informados.\nNão podem conter os caracteres:   ;  --  #  // /*  */  =');
                        }
                        else {
                            window.alert('Não foi possível realizar a busca de informações.')
                        }
                    }
                }
            }
            if (wCtrlBuscaDados === 1) {
                if (wAuxDataCep.length > 0) {
                    setSelectedIdLogr(null);
                    setSelectedIdCep(wAuxDataCep[0].id)
                    setTexCep(wAuxDataCep[0].cep)
                    setIdCidadeCep(wAuxDataCep[0].idCidade);
                    setCidadeCep(wAuxDataCep[0].nomeCidade + "-" + wAuxDataCep[0].uf);
                    setTexLogradouroCep(wAuxDataCep[0].descLogradrouroComp);
                    setLatitude(wAuxDataCep[0].lat.toString());
                    setLongitude(wAuxDataCep[0].lng.toString());
                    setDispAnimaAguardeCep(0);
                }
                else {
                    setDispAnimaAguardeCep(0);
                    setMensagem('CEP não encontrado em nossa base!')
                }
            }
        }
    }

    async function buscaDadosCid() {
        const requestOptions = { method: 'GET' };
        var wCtrlBuscaDados = 0;
        var wAuxDataCidades = [];

        for (let i = 0; i <= 0; i++) {
            if (i == 0) {
                const response = await fetch('https://www.iqueest.com.br/ws_iqueest/localizacao/cidades?cidade=' + descCidade, requestOptions);
                if (response.ok == true) {
                    wCtrlBuscaDados += 1;
                    wAuxDataCidades = await response.json();
                }
                else {
                    setDispAnimaAguardeCid(0);

                    if (response.status == 418) {
                        window.alert('Dados informados com caracteres inválidos!\nRevise os dados informados.\nNão podem conter os caracteres:   ;  --  #  // /*  */  =');
                    }
                    else {
                        window.alert('Não foi possível realizar a busca de informações.');
                    }
                }
            }
        }
        if (wCtrlBuscaDados === 1) {
            if (wAuxDataCidades.length > 0) {
                setDataCidades(wAuxDataCidades);
                setPopupCidAberto(true);
                setDispAnimaAguardeCid(0);
            }
            else {
                setDispAnimaAguardeCid(0);
                setMensagem('Nenhuma Cidade encontrada!');
            }
        }
    }

    async function buscaDadosLogr() {
        const requestOptions = { method: 'GET' };
        var wCtrlBuscaDados = 0;
        var wAuxDataLogradouros = [];

        for (let i = 0; i <= 0; i++) {
            if (i == 0) {
                const response = await fetch('https://www.iqueest.com.br/ws_iqueest/localizacao/enderecos?codcidade=' + selectedIdCid + '&endereco=' + descLogradouro, requestOptions);
                if (response.ok == true) {
                    wCtrlBuscaDados += 1;
                    wAuxDataLogradouros = await response.json();
                }
                else {
                    setDispAnimaAguardeLogr(0);

                    if (response.status == 418) {
                        window.alert('Dados informados com caracteres inválidos!\nRevise os dados informados.\nNão podem conter os caracteres:   ;  --  #  // /*  */  =');
                    }
                    else {
                        window.alert('Não foi possível realizar a busca de informações.');
                    }
                }
            }
        }
        if (wCtrlBuscaDados === 1) {
            if (wAuxDataLogradouros.length > 0) {
                setDataLogradouros(wAuxDataLogradouros);
                setPopupLogrAberto(true);
                setDispAnimaAguardeLogr(0);
            }
            else {
                setDispAnimaAguardeLogr(0);
                setMensagem('Logradouro não encontrado em nossa base!')
            }
        }
    }

    function cancelaEscolhaCep() {
        setIdCidadeCep(null);
        setCidadeCep("");
        setSelectedIdCep(null);
        setTexCep("");
        setTexLogradouroCep("");
        setTexCidade("");
        setTexLogradouro("");
    }

    function confirmaEscolhaCep() {
        if (dadosGlobais.localizacao !== "Cep" || dadosGlobais.idCep !== selectedIdCep) {
            dispatchDadosGlobais({ type: 'atuAgendaAtuDestaques', payload: true });
            dispatchDadosGlobais({ type: 'atuAtualizaFavoritos', payload: true });
            dispatchDadosGlobais({ type: 'atuLocalizacao', payload: "Cep" });
            dispatchDadosGlobais({ type: 'atuIdCidadeLogra', payload: null });
            dispatchDadosGlobais({ type: 'atuCidadeLogra', payload: "" });
            dispatchDadosGlobais({ type: 'atuIdLogradouro', payload: null });
            dispatchDadosGlobais({ type: 'atuIdCidadeCep', payload: idCidadeCep });
            dispatchDadosGlobais({ type: 'atuCidadeCep', payload: cidadeCep });
            dispatchDadosGlobais({ type: 'atuIdCep', payload: selectedIdCep });
            dispatchDadosGlobais({ type: 'atuCep', payload: texCep });
            dispatchDadosGlobais({ type: 'atuLogradouro', payload: texLogradouroCep });
            dispatchDadosGlobais({ type: 'atuLatLocal', payload: latitude });
            dispatchDadosGlobais({ type: 'atuLngLocal', payload: longitude });
        }
        props.retorna();
    }

    function montaListaSelecaoCid() {
        //Keyboard.dismiss();

        if (descCidade === "") {
            setMensagem('Digite uma Cidade!');
        }
        else {
            if (descCidade.substring(descCidade.length - 1) == " ") {
                setDescCidade((valor) => valor.substring(0, valor.length - 1))
            }
            setDispAnimaAguardeCid(1);
            setDataCidades([]);
            buscaDadosCid();
        }
    }

    function cancelaEscolhaCid() {
        setSelectedIdCid(null);
        setSelectedIdLogr(null);
        setDescCidade(null);
        setTexCidade("");
        setPopupCidAberto(false);
        setTexLogradouro("");
        setTexCep("");
        setTexLogradouroCep("");
    }

    function montaListaSelecaoLogr() {
        //Keyboard.dismiss();

        if (descLogradouro === "") {
            setMensagem('Digite um Logradouro!');
        }
        else {
            if (descLogradouro.substring(descLogradouro.length - 1) == " ") {
                setDescLogradouro((valor) => valor.substring(0, valor.length - 1))
            }
            setDispAnimaAguardeLogr(1);
            setDataLogradouros([]);
            buscaDadosLogr();
        }
    }

    function cancelaEscolhaLogr() {
        setSelectedIdLogr(null);
        setSelectedIdCid(null);
        setDescLogradouro(null);
        setTexLogradouro("");
        setPopupLogrAberto(false);
        setTexCep("");
        setTexLogradouroCep("");
    }

    function confirmaEscolhaLogr() {
        if (dadosGlobais.localizacao !== "Logradouro" || dadosGlobais.idLogradouro !== selectedIdLogr) {
            dispatchDadosGlobais({ type: 'atuAgendaAtuDestaques', payload: true });
            dispatchDadosGlobais({ type: 'atuAtualizaFavoritos', payload: true });
            dispatchDadosGlobais({ type: 'atuLocalizacao', payload: "Logradouro" });
            dispatchDadosGlobais({ type: 'atuIdCidadeCep', payload: null });
            dispatchDadosGlobais({ type: 'atuCidadeCep', payload: "" });
            dispatchDadosGlobais({ type: 'atuIdCep', payload: null });
            dispatchDadosGlobais({ type: 'atuCep', payload: "" });
            dispatchDadosGlobais({ type: 'atuIdCidadeLogra', payload: selectedIdCid });
            dispatchDadosGlobais({ type: 'atuCidadeLogra', payload: texCidade });
            dispatchDadosGlobais({ type: 'atuIdLogradouro', payload: selectedIdLogr });
            dispatchDadosGlobais({ type: 'atuLogradouro', payload: texLogradouro });
            dispatchDadosGlobais({ type: 'atuLatLocal', payload: latitude });
            dispatchDadosGlobais({ type: 'atuLngLocal', payload: longitude });
        }
        props.retorna();
    }

    return (
        <>
            <p style={{ marginTop: 10 }}> Escolher Local </p>
            <Grid container item direction="column" alignSelf="flex-start">
                {selectedIdCid == null ?
                    <>
                        {selectedIdCep == null ?
                            <TextField style={{ width: '45%', marginTop: 10, marginLeft: 20 }}
                                id="textCEP"
                                label="CEP"
                                value={texCep}
                                onChange={(event) => {
                                    const wVal = event.target.value;
                                    setTexCep(wVal);
                                }}
                                onKeyPress={(event) => {
                                    if (event.key == "Enter") {
                                        buscaDadosCep();
                                    }
                                }}
                                size="small"
                                placeholder="Digite um CEP"
                                variant="outlined"
                                inputProps={{
                                    inputMode: 'numeric',
                                    style: ({ fontSize: 16 })
                                }}
                                InputProps={{
                                    style: ({ paddingRight: 10 }),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <>
                                                {dispAnimaAguardeCep !== 1 ?
                                                    <IconButton size="small" onClick={buscaDadosCep} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                                        <img
                                                            style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                                            src={search2}
                                                        />
                                                    </IconButton>
                                                :
                                                    <img
                                                        style={{ width: 25, height: 25, resizeMode: 'stretch', marginLeft: '-5%' }}
                                                        src={aguarde}
                                                    />
                                                }
                                            </>
                                        </InputAdornment>

                                    ),
                                    classes: {
                                        input: classes.fonteMenorTextField,
                                    }
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        :
                            <TextField style={{ width: '45%', marginTop: 10, marginLeft: 20 }}
                                id="textCEP"
                                label="CEP"
                                value={texCep}
                                placeholder="Digite um CEP"
                                variant="outlined"
                                disabled
                                size="small"
                                inputProps={{
                                    inputMode: 'numeric',
                                    style: ({ fontSize: 16 })
                                }}
                                InputProps={{
                                    style: ({ paddingRight: 10 }),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={cancelaEscolhaCep} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                                <img
                                                    style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                                    src={cancel}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: classes.fonteMenorTextField,
                                    }
                                }}
                            />
                        }
                    </>
                :
                    null
                }

                {selectedIdCep == null && selectedIdCid == null ?
                    <Grid container item style={{ marginTop: 30, marginBottom: 30 }} direction="column" alignItems="center">
                        <hr style={{ width: 150 }} />
                        <span style={{ width: 30, marginTop: -22, backgroundColor:'#F5F5F5' }}> ou </span>
                    </Grid>
                :
                    null
                }

                {selectedIdCep == null ?
                    <>
                        {selectedIdCid == null ?
                            <>
                                <Autocomplete style={{ width: '70%', marginTop: 10, marginLeft: 20 }}
                                    id="dropCidade"
                                    value={texCidade}
                                    inputValue={descCidade}
                                    onInputChange={(event, newInputValue) => {
                                        setDescCidade(newInputValue);
                                        if (texCidade != newInputValue) {
                                            setSelectedIdCid(null);
                                            setTexCidade("");
                                        }
                                    }}
                                    options={dataCidades.map((valor, indice, vetor) => { return valor.nome + "-" + valor.uf })}
                                    size="small"
                                    open={popupCidAberto}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Cidade"
                                            placeholder="Digite uma Cidade          "
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                style: ({ textTransform: 'capitalize', fontSize: 16 })
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: ({ paddingRight: 10 }),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <>
                                                            {dispAnimaAguardeCid !== 1 ?
                                                                <IconButton size="small" onClick={montaListaSelecaoCid} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                                                    <img
                                                                        style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                                                        src={search2}
                                                                    />
                                                                </IconButton>
                                                            :
                                                                <img
                                                                    style={{ width: 25, height: 25, resizeMode: 'stretch', marginLeft: '-5%' }}
                                                                    src={aguarde}
                                                                />
                                                            }
                                                        </>
                                                    </InputAdornment>

                                                ),
                                                classes: {
                                                    input: classes.fonteMenorTextField,
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    }
                                    onChange={async (event, newValue) => {
                                        if (newValue) {
                                            setSelectedIdCep(null);
                                            setSelectedIdLogr(null);
                                            setSelectedIdCid(dataCidades.find((valor, indice, vetor) => { return valor.nome + "-" + valor.uf === newValue }).id);
                                            setTexCidade(newValue);
                                            setPopupCidAberto(false);
                                        }
                                    }}
                                    onBlur={() => {
                                        if (descCidade && descCidade.toString().length > 1) {
                                            if (selectedIdCid == null) {
                                                montaListaSelecaoCid();
                                            }
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode == 13 && popupCidAberto == false) {
                                            if (descCidade && descCidade.toString().length > 1) {
                                                montaListaSelecaoCid();
                                            }
                                        }
                                    }}
                                />
                                <TextField style={{ width: '80%', marginTop: 10, marginLeft: 20 }}
                                    id="textLogradouro"
                                    label="Logradouro"
                                    placeholder="Digite um Logradouro          "
                                    variant="outlined"
                                    disabled
                                    size="small"
                                    value={texLogradouro}
                                />
                            </>
                        :
                            <>
                                <TextField style={{ width: '70%', marginTop: 10, marginLeft: 20 }}
                                    id="textCidade"
                                    label="Cidade"
                                    placeholder="Digite uma Cidade"
                                    variant="outlined"
                                    disabled
                                    size="small"
                                    value={texCidade}
                                    inputProps={{
                                        style: ({ textTransform: 'capitalize', fontSize: 16 })
                                    }}
                                    InputProps={{
                                        style: ({ paddingRight: 10 }),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton size="small" onClick={cancelaEscolhaCid} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                                    <img
                                                        style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                                        src={cancel}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.fonteMenorTextField,
                                        }
                                    }}
                                />

                                {selectedIdLogr == null ?
                                    <Autocomplete style={{ width: '80%', marginTop: 10, marginLeft: 20 }}
                                        id="dropLogradouro"
                                        value={texLogradouro}
                                        inputValue={descLogradouro}
                                        onInputChange={(event, newInputValue) => {
                                            setDescLogradouro(newInputValue);
                                            if (texLogradouro != newInputValue) {
                                                setSelectedIdLogr(null);
                                                setTexLogradouro("");
                                            }
                                        }}
                                        options={dataLogradouros.map((valor, indice, vetor) => { return valor.descLogradrouroComp })}
                                        size="small"
                                        open={popupLogrAberto}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label="Logradouro"
                                                placeholder="Digite um Logradouro          "
                                                variant="outlined"
                                                fullWidth
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: ({ textTransform: 'capitalize', fontSize: 16 })
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: ({ paddingRight: 10 }),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <>
                                                                {dispAnimaAguardeLogr !== 1 ?
                                                                    <IconButton size="small" onClick={montaListaSelecaoLogr} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                                                        <img
                                                                            style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                                                            src={search2}
                                                                        />
                                                                    </IconButton>
                                                                :
                                                                    <img
                                                                        style={{ width: 25, height: 25, resizeMode: 'stretch', marginLeft: '-5%' }}
                                                                        src={aguarde}
                                                                    />
                                                                }
                                                            </>
                                                        </InputAdornment>

                                                    ),
                                                    classes: {
                                                        input: classes.fonteMenorTextField,
                                                    }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        }
                                        onChange={async (event, newValue) => {
                                            if (newValue) {
                                                setSelectedIdCep(null);
                                                setSelectedIdLogr(dataLogradouros.find((valor, indice, vetor) => { return valor.descLogradrouroComp === newValue }).id);
                                                setTexLogradouro(newValue);
                                                setLatitude(dataLogradouros.find((valor, indice, vetor) => { return valor.descLogradrouroComp === newValue }).lat.toString());
                                                setLongitude(dataLogradouros.find((valor, indice, vetor) => { return valor.descLogradrouroComp === newValue }).lng.toString());
                                                setPopupLogrAberto(false);
                                            }
                                        }}
                                        onBlur={() => {
                                            if (descLogradouro && descLogradouro.toString().length > 1) {
                                                if (selectedIdLogr == null) {
                                                    montaListaSelecaoLogr();
                                                }
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode == 13 && popupLogrAberto == false) {
                                                if (descLogradouro && descLogradouro.toString().length > 1) {
                                                    montaListaSelecaoLogr();
                                                }
                                            }
                                        }}
                                    />
                                :
                                    <>
                                        <TextField style={{ width: '80%', marginTop: 10, marginLeft: 20 }}
                                            id="textLogradouro"
                                            label="Logradouro"
                                            placeholder="Digite um Logradouro          "
                                            variant="outlined"
                                            disabled
                                            size="small"
                                            value={texLogradouro}
                                            inputProps={{
                                                style: ({ textTransform: 'capitalize', fontSize: 16 })
                                            }}
                                            InputProps={{
                                                style: ({ paddingRight: 10 }),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton size="small" onClick={cancelaEscolhaLogr} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                                            <img
                                                                style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                                                src={cancel}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes.fonteMenorTextField,
                                                }
                                            }}
                                        />
                                        <Button variant="outlined" onClick={confirmaEscolhaLogr} style={{ alignSelf: "center", justifyContent: 'center', marginTop: 40, width: '20%', height: 40, borderColor: 'black', borderWidth: 1, borderRadius: 4 }} >
                                            <p style={{ color: 'black', paddingHorizontal: 10, textTransform: 'capitalize' }}>Confirma</p>
                                        </Button>
                                    </>
                                }
                            </>
                        }
                    </>
                :
                    <>
                        <p style={{ marginTop: 30 }}>{cidadeCep}</p>
                        <p style={{ marginTop: 10 }}>{texLogradouroCep}</p>
                        <Button variant="outlined" onClick={confirmaEscolhaCep} style={{ alignSelf: "center", justifyContent: 'center', marginTop: 40, width: '20%', height: 40, borderColor: 'black', borderWidth: 1, borderRadius: 4 }} >
                            <p style={{ color: 'black', paddingHorizontal: 10, textTransform: 'capitalize' }}>Confirma</p>
                        </Button>
                    </>
                }
            </Grid>
            <Dialog open={mensagem.length > 0} fullWidth={true} maxWidth="xs">
                <DialogContent>
                    <DialogContentText>{mensagem}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        //if (mensagem === '') () => algo...;
                        setMensagem("");
                    }} color="primary" style={{ textTransform: 'none' }}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default EscolheLocal;
