import React, { useState, useEffect, forwardRef, useImperativeHandle, Fragment, useLayoutEffect, useReducer, useContext, useCallback, useRef } from 'react';

const SoftCarrossel = forwardRef((props, ref) => {
    const [parImgsCrsl, setParImgsCrsl] = useState([]);
    const [direcao, setDirecao] = useState("+");
    const [vetImgs, setVetImgs] = useState(props.vetorImagens);
    const [indImg, setIndImg] = useState(0);
    const [cloneItemExcluido, setCloneItemExcluido] = useState(null);
    const [idTimer, setIdTimer] = useState(null);

    useImperativeHandle(ref, () => ({
        avanca,
        retrocede,
        adiciona,
        remove
    }));

    async function crslAuto() {
        if (vetImgs.length > 1) {
            setIndImg((ind) => ind < vetImgs.length - 1 ? ind + 1 : 0);
            props.informaPaiAtuIndAuto(indImg < vetImgs.length - 1 ? indImg + 1 : 0);
            setDirecao("+");
        }
    }

    async function setaCarrossel() {
        if (direcao != null) {
            if (direcao === "+") {
                if (cloneItemExcluido != null) {
                    setParImgsCrsl([cloneItemExcluido, vetImgs[indImg]])

                    var t = document.getElementById("crslTras" + props.ind.toString());
                    t.style.transition = 'none';
                    t.style.transform = 'translateX(0%)';
                }
                else if (indImg > 0) {
                    setParImgsCrsl([vetImgs[indImg - 1], vetImgs[indImg]])

                    var t = document.getElementById("crslTras" + props.ind.toString());
                    t.style.transition = 'none';
                    t.style.transform = 'translateX(0%)';
                }
                else {
                    if (vetImgs.length > 1) {
                        setParImgsCrsl([vetImgs[vetImgs.length - 1], vetImgs[indImg]])

                        var t = document.getElementById("crslTras" + props.ind.toString());
                        t.style.transition = 'none';
                        t.style.transform = 'translateX(0%)';
                    }
                    else {
                        setParImgsCrsl([vetImgs[indImg]])
                    }
                }

                if (vetImgs.length > 1) {
                    var c = document.getElementById("crsl" + props.ind.toString());
                    c.style.transition = 'none';
                    c.style.transform = 'translateX(100%)';
                }
            }
            else if (direcao === "-") {
                if (cloneItemExcluido != null) {
                    setParImgsCrsl([cloneItemExcluido, vetImgs[indImg]])

                    var t = document.getElementById("crslTras" + props.ind.toString());
                    t.style.transition = 'none';
                    t.style.transform = 'translateX(0%)';
                }
                else if (indImg < vetImgs.length - 1) {
                    setParImgsCrsl([vetImgs[indImg + 1], vetImgs[indImg]])

                    var t = document.getElementById("crslTras" + props.ind.toString());
                    t.style.transition = 'none';
                    t.style.transform = 'translateX(0%)';
                }
                else {
                    setParImgsCrsl([vetImgs[indImg]])
                }

                if (vetImgs.length > 1) {
                    var c = document.getElementById("crsl" + props.ind.toString());
                    c.style.transition = 'none';
                    c.style.transform = 'translateX(-100%)';
                }
            }
        }
    }

    useEffect(() => {
        if (vetImgs.length > 1) {
            if (idTimer == null) {
                setIdTimer(setInterval(crslAuto, 4000));
            }
        }
    }, [idTimer]);

    useEffect(() => {
        setaCarrossel();
    }, [vetImgs, indImg, direcao, cloneItemExcluido]);

    useEffect(() => {
        if (direcao == null && cloneItemExcluido != null) {
            setCloneItemExcluido(null);
        }
    }, [direcao, cloneItemExcluido]);

    async function transicaoAoCarregar() {
        if (vetImgs.length > 1) {
            if (direcao === "+") {
                if (parImgsCrsl.length > 1) {
                    let t = document.getElementById("crslTras" + props.ind.toString());

                    setTimeout(() => {
                        t.style.transform = 'translateX(-100%)';
                        t.style.transition = 'transform linear 0.5s';
                    }, 100);
                }

                let c = document.getElementById("crsl" + props.ind.toString());

                setTimeout(() => {
                    c.style.transform = 'translateX(0%)';
                    c.style.transition = 'transform linear 0.5s';
                }, 100);
            }
            else if (direcao === "-") {
                if (parImgsCrsl.length > 1) {
                    let t = document.getElementById("crslTras" + props.ind.toString());

                    setTimeout(() => {
                        t.style.transform = 'translateX(100%)';
                        t.style.transition = 'transform linear 0.5s';
                    }, 100);
                }

                let c = document.getElementById("crsl" + props.ind.toString());

                setTimeout(() => {
                    c.style.transform = 'translateX(0%)';
                    c.style.transition = 'transform linear 0.5s';
                }, 100);
            }
        }

        setDirecao(null);
    }

    if (document.getElementById("crsl" + props.ind.toString()) != null) {
        document.getElementById("crsl" + props.ind.toString()).onload = (event) => {
            transicaoAoCarregar();
        }
    }

    function avanca(ind) {
        if (indImg < vetImgs.length - 1) {
            setIndImg(ind);
            setDirecao("+");
        }
    }

    function retrocede(ind) {
        if (indImg > 0) {
            setIndImg(ind);
            setDirecao("-");
        }
    }

    function adiciona(item) {
        setVetImgs((vet) => [...vet, item]);
        setIndImg((ind) => ind + 1);
        setDirecao("+");
    }

    function remove(ind) {
        setCloneItemExcluido(vetImgs[ind]);

        vetImgs.splice(ind, 1);
        setVetImgs(vetImgs);

        if (ind > vetImgs.length - 1) {
            setIndImg(vetImgs.length - 1);
            setDirecao("-");
        }
        else {
            setDirecao("+");
        }
    }

    return (
        <Fragment key={"fr" + props.ind.toString()}>
            <img key={"crslTras" + props.ind.toString()} style={{ position: 'absolute', top: 0, left: 0, visibility: parImgsCrsl.length > 1 ? 'visible' : 'hidden' }} id={"crslTras" + props.ind.toString()} width="100%" height="100%" src={parImgsCrsl.length > 1 ? parImgsCrsl[0] : undefined} alt="bannerTras" />
            <img key={"crsl" + props.ind.toString()} style={{ position: 'absolute', top: 0, left: 0 }} id={"crsl" + props.ind.toString()} width="100%" height="100%" src={parImgsCrsl.length > 1 ? parImgsCrsl[1] : parImgsCrsl[0]} alt="banner" />
        </Fragment>
    )
})

export default SoftCarrossel;
