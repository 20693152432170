import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';

import { dadosGlobaisContext } from './ContextGlobal';

import aguarde from './imagens/aguarde.gif';
import cancel from './imagens/cancel.png';
import search2 from './imagens/search2.png';

const useStyles = makeStyles((theme) => ({
    gridEmForm: {
        marginBottom: '20px',
        alignItems: 'center'
    },
    fonteMiniTextField: {
        fontSize: 10
    },
    fonteMenorTextField: {
        fontSize: 12
    },
    fonteMenorInputLabel: {
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

var wUnidadeLargura = 0;

function EscolheCidade(props) {
    const [dadosGlobais, dispatchDadosGlobais] = useContext(dadosGlobaisContext);

    const [mensagem, setMensagem] = useState("");
    const [baseLargura, setBaseLargura] = useState(0);
    const [dataCidades, setDataCidades] = useState([]);
    const [selectedId, setSelectedId] = useState(dadosGlobais.idCidade);
    const [texCidade, setTexCidade] = useState(dadosGlobais.cidade);
    const [popupCidAberto, setPopupCidAberto] = useState(false);
    const [descCidade, setDescCidade] = useState(null);
    const [dispAnimaAguarde, setDispAnimaAguarde] = useState(0);

    const classes = useStyles();

    function montaListaSelecao() {
        //Keyboard.dismiss();

        if (descCidade === "") {
            setMensagem('Digite uma Cidade!');
        }
        else {
            if (descCidade.substring(descCidade.length - 1) == " ") {
                setDescCidade((valor) => valor.substring(0, valor.length - 1))
            }
            setDispAnimaAguarde(1);
            setDataCidades([]);
            buscaDados();
        }
    }

    async function buscaDados() {
        const requestOptions = { method: 'GET' };
        var wCtrlBuscaDados = 0;
        var wAuxDataCidades = [];

        for (let i = 0; i <= 0; i++) {
            if (i == 0) {
                const response = await fetch('https://www.iqueest.com.br/ws_iqueest/localizacao/cidades?cidade=' + descCidade, requestOptions);
                if (response.ok == true) {
                    wCtrlBuscaDados += 1;
                    wAuxDataCidades = await response.json();
                }
                else {
                    setDispAnimaAguarde(0);

                    if (response.status == 418) {
                        window.alert('Dados informados com caracteres inválidos!\nRevise os dados informados.\nNão podem conter os caracteres:   ;  --  #  // /*  */  =');
                    }
                    else {
                        window.alert('Não foi possível realizar a busca de informações.');
                    }
                }
            }
        }
        if (wCtrlBuscaDados === 1) {
            if (wAuxDataCidades.length > 0) {
                setDataCidades(wAuxDataCidades);
                setPopupCidAberto(true);
                setDispAnimaAguarde(0);
            }
            else {
                setDispAnimaAguarde(0);
                setSelectedId(null);
                setDescCidade(null);
                setTexCidade("");
                setMensagem('Nenhuma Cidade encontrada!');
            }
        }
    }

    function cancelaEscolha() {
        setSelectedId(null);
        setDescCidade(null);
        setTexCidade("");
        setPopupCidAberto(false);
    }

    function confirmaEscolha() {
        if (dadosGlobais.localizacao !== "Cidade" || dadosGlobais.idCidade !== selectedId) {
            dispatchDadosGlobais({ type: 'atuAgendaAtuDestaques', payload: true });
            dispatchDadosGlobais({ type: 'atuAtualizaFavoritos', payload: true });
            dispatchDadosGlobais({ type: 'atuLocalizacao', payload: "Cidade" });
            dispatchDadosGlobais({ type: 'atuIdCidade', payload: selectedId });
            dispatchDadosGlobais({ type: 'atuCidade', payload: texCidade });
        }
        props.retorna();
    }

    useEffect(() => {
        wUnidadeLargura = baseLargura / 400;
    }, [baseLargura]);

    useEffect(() => {
        if (baseLargura == 0) {
            // BASEADO EM window.innerWidth   >= 1200 (lg)   OU   >= 600 (sm)   OU   >= 0 (xs)
            if (window.innerWidth >= 1200) {
                setBaseLargura(window.innerWidth / 12 * 4);
            }
            else if (window.innerWidth >= 600) {
                setBaseLargura(window.innerWidth / 12 * 8);
            }
            else {
                setBaseLargura(window.innerWidth * 0.98); // USADO 0.98 PORQUE SE USAR 100% DE innerWidth DÁ UM MONTE DE PROBLEMAS EM NAVEGADOR MOBILE
            }
        }
    });

    return (
        <>
        <p style={{ marginTop: 10 }}> Busca por Cidade </p>
        <Grid container item direction="column" alignSelf="flex-start">
            {selectedId == null ?
                <>
                    <Autocomplete style={{ width: '70%', marginTop: 10, marginLeft: 20 }}
                        id="dropCidade"
                        value={texCidade}
                        inputValue={descCidade}
                        onInputChange={(event, newInputValue) => {
                            setDescCidade(newInputValue);
                            if (texCidade != newInputValue) {
                                setSelectedId(null);
                                setTexCidade("");
                            }
                        }}
                        options={dataCidades.map((valor, indice, vetor) => { return valor.nome + "-" + valor.uf })}
                        size="small"
                        open={popupCidAberto}
                        renderInput={(params) =>
                            <TextField {...params}
                                label="Cidade"
                                placeholder="Digite uma Cidade          "
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    style: ({ textTransform: 'capitalize', fontSize: 16 })
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    style: ({ paddingRight: 10 }),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <>
                                                {dispAnimaAguarde !== 1 ?
                                                    <IconButton size="small" onClick={montaListaSelecao} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                                        <img
                                                            style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                                            src={search2}
                                                        />
                                                    </IconButton>
                                                :
                                                    <img
                                                        style={{ width: 25, height: 25, resizeMode: 'stretch', marginLeft: '-5%' }}
                                                        src={aguarde}
                                                    />
                                                }
                                            </>
                                        </InputAdornment>

                                    ),
                                    classes: {
                                        input: classes.fonteMenorTextField,
                                    }
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        }
                        onChange={async (event, newValue) => {
                            if (newValue) {
                                setSelectedId(dataCidades.find((valor, indice, vetor) => { return valor.nome + "-" + valor.uf === newValue }).id);
                                setTexCidade(newValue);
                                setPopupCidAberto(false);
                            }
                        }}
                        onBlur={() => {
                            if (descCidade && descCidade.toString().length > 1) {
                                if (selectedId == null) {
                                    montaListaSelecao();
                                }
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode == 13 && popupCidAberto == false) {
                                if (descCidade && descCidade.toString().length > 1) {
                                    montaListaSelecao();
                                }
                            }
                        }}
                    />
                </>
            :
                <>
                    <TextField
                        style={{ width: '70%', marginTop: 10, marginLeft: 20 }}
                        id="textCidade"
                        label="Cidade"
                        placeholder="Digite uma Cidade          "
                        variant="outlined"
                        disabled
                        size="small"
                        value={texCidade}
                        inputProps={{
                            style: ({ textTransform: 'capitalize', fontSize: 16 })
                        }}
                        InputProps={{
                            style: ({ paddingRight: 10 }),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size="small" onClick={cancelaEscolha} style={{ height: 30, width: 30, paddingTop: 0, marginLeft: '-5%' }} >
                                        <img
                                            style={{ width: 25, height: 25, resizeMode: 'stretch' }}
                                            src={cancel}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.fonteMenorTextField,
                            }
                        }}
                    />
                    {!popupCidAberto ?
                        <Button variant="outlined" onClick={confirmaEscolha} style={{ alignSelf: "center", justifyContent: 'center', marginTop: 40, width: '20%', height: 40, borderColor: 'black', borderWidth: 1, borderRadius: 4 }} >
                            <p style={{ color: 'black', paddingHorizontal: 10, textTransform: 'capitalize' }}>Confirma</p>
                        </Button>
                    :
                        null
                    }
                </>
            }
        </Grid>
        <Dialog open={mensagem.length > 0} fullWidth={true} maxWidth="xs">
            <DialogContent>
                <DialogContentText>{mensagem}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    //if (mensagem === '') () => algo...;
                    setMensagem("");
                }} color="primary" style={{ textTransform: 'none' }}>OK</Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default EscolheCidade;
