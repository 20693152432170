import './App.css';
import React, { useState, useEffect, useLayoutEffect, useReducer, useContext, useCallback, useRef } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import { isMobile } from 'react-device-detect';

import googlemaps from './imagens/google-maps_2.png';
import arrowdown from './imagens/arrow-down.png';
import arrowup from './imagens/arrow-up.png';
import fone from './imagens/telefone.png';
import whatsapp from './imagens/whatsapp.png';
import whatsappGreen from './imagens/whatsapp-green.png';
import instagram from './imagens/instagram.png';
import facebook from './imagens/facebook.png';
import youtube from './imagens/youtube.png';
import twitter from './imagens/twitter.png';
import linkedin from './imagens/linkedin.png';
import internet from './imagens/internet.png';
import heart from './imagens/heart.png';
import promocaoRed from './imagens/promocao-red.png';

import { RegistraClique } from './Requisicoes';
import SoftCarrossel from './SoftCarrossel';

import { anunciosContext } from './App';

var wAuxDiasSemana = "";
var wVetDescricao = "";
var wUnidadeLargura = 0;
var wBaseLargura = 0;

function Anuncio(props) {
    const setAltura = useContext(anunciosContext);

    const wAnuncioRef = useRef();

    const [infoAnuncio, setInfoAnuncio] = useState(
        {
            titulo: props.titulo,
            descricao: props.descricao,
            cidade_uf: props.cidade_uf,
            km: props.km,
            lat: props.lat,
            lng: props.lng,
            grifar: props.grifar != null ? props.grifar : [],
            vetDiaSem: [
                props.seg != null && props.seg == 'X' ? true : false,
                props.ter != null && props.ter == 'X' ? true : false,
                props.qua != null && props.qua == 'X' ? true : false,
                props.qui != null && props.qui == 'X' ? true : false,
                props.sex != null && props.sex == 'X' ? true : false,
                props.sab != null && props.sab == 'X' ? true : false,
                props.dom != null && props.dom == 'X' ? true : false,
            ],
            disponibilidade: props.disponibilidade,
            telefone: props.telefone,
            whatsapp: props.whatsapp,
            instagram: props.instagram,
            facebook: props.facebook,
            youtube: props.youtube,
            twitter: props.twitter,
            linkedin: props.linkedin,
            site: props.site,
            url: props.url,
            linkappmapas: props.linkappmapas,
            tempromo: props.tempromo
        }
    );

    const [expandido, setExpandido] = useState(false);
    const [maisInfo, setMaisInfo] = useState(false);
    const [indImgBanners, setIndImgBanners] = useState(0);
    const [mostraWhats, setMostraWhats] = useState(false);

    const wRefSoftCrsl = useRef();

    function mostraMaisInfo() {
        if (infoAnuncio.url.length > 0) {
            if ((infoAnuncio.telefone && infoAnuncio.telefone != " ") || infoAnuncio.vetDiaSem.filter(x => x == true).length > 0 || infoAnuncio.disponibilidade) {
                let vDia = [];
                let vStr = [];
                wAuxDiasSemana = "";

                if (infoAnuncio.vetDiaSem[0]) { vDia.push(0); vStr.push('SEG'); }
                if (infoAnuncio.vetDiaSem[1]) { vDia.push(1); vStr.push('TER'); }
                if (infoAnuncio.vetDiaSem[2]) { vDia.push(2); vStr.push('QUA'); }
                if (infoAnuncio.vetDiaSem[3]) { vDia.push(3); vStr.push('QUI'); }
                if (infoAnuncio.vetDiaSem[4]) { vDia.push(4); vStr.push('SEX'); }
                if (infoAnuncio.vetDiaSem[5]) { vDia.push(5); vStr.push('SÁB'); }
                if (infoAnuncio.vetDiaSem[6]) { vDia.push(6); vStr.push('DOM'); }

                if (vDia.length > 0) {
                    wAuxDiasSemana = vStr[0]

                    let seq = true;

                    for (let xx = 1; xx < vDia.length; xx++) {
                        if (vDia[xx - 1] + 1 != vDia[xx]) {
                            seq = false;
                        }

                        wAuxDiasSemana = wAuxDiasSemana + ' / ' + vStr[xx];
                    }

                    if (seq == true && vDia.length > 1) {
                        wAuxDiasSemana = wAuxDiasSemana.substring(0, 3) + ' a ' + wAuxDiasSemana.substring(wAuxDiasSemana.length - 3);
                    }
                }

                setMaisInfo(true);
            }
        }
    }

    ////
    // ROTINA PARA DESTACAR OS TRECHOS DE TEXTO QUE DEVEM SER GRIFADOS
    ////
    var wAuxDescricao = infoAnuncio.descricao;

    if (infoAnuncio.grifar.length > 0) {
        infoAnuncio.grifar.forEach((valor) => { wAuxDescricao = wAuxDescricao.replace(valor, 'ø' + valor + 'ø') });

        wVetDescricao = wAuxDescricao.split('ø').map((valor, indice) => {
            if (infoAnuncio.grifar.indexOf(valor) >= 0) {
                return <span key={indice.toString()} style={{ color: 'white', backgroundColor: '#F9C205' }}>{valor}</span>;
            } else {
                return <span key={indice.toString()}>{valor}</span>;
            }
        });
    }
    else {
        wVetDescricao = wAuxDescricao;
    }
    ////
    // FIM - ROTINA PARA DESTACAR OS TRECHOS DE TEXTO QUE DEVEM SER GRIFADOS
    ////

    var wTexCidKm = props.km.length > 0 ? props.cidade_uf + "   ± " + props.km + " Km" : props.cidade_uf.length > 3 ? props.cidade_uf : "";

    useEffect(() => {
        setAltura(props.ind, wAnuncioRef.current.getBoundingClientRect().height + 10);
    }, [expandido]);

    function softCrslAtuIndAuto(ind) {
        setIndImgBanners(ind);
    }

    // BASEADO EM window.innerWidth   >= 1200 (lg)   OU   >= 600 (sm)   OU   >= 0 (xs)
    if (window.innerWidth >= 1200) {
        wBaseLargura = window.innerWidth / 12 * 4;
    }
    else if (window.innerWidth >= 600) {
        wBaseLargura = window.innerWidth / 12 * 8;
    }
    else {
        wBaseLargura = window.innerWidth * 0.98; // USADO 0.98 PORQUE SE USAR 100% DE innerWidth DÁ UM MONTE DE PROBLEMAS EM NAVEGADOR MOBILE
    }

    wUnidadeLargura = wBaseLargura / 400;

    return (
        <Card ref={wAnuncioRef} variant="outlined"
            style={{
                backgroundColor: '#FFFFFF',
                width: wBaseLargura * 0.91,
                height: expandido ? wBaseLargura / 72 * 61 * 1.54 : wBaseLargura / 72 * 61,
                padding: wUnidadeLargura * 10,
                paddingTop: wUnidadeLargura * 5,
                paddingBottom: wUnidadeLargura * 5,
                marginBottom: wUnidadeLargura * 10
            }}>
            <Grid container direction="row" style={{ height: wBaseLargura / 40 * 3 }} xs={12} alignItems="center" justifyContent="space-between">
                <Grid container item direction="row" style={{ height: '100%' }} xs={8} alignItems="center" justifyContent="flex-start">
                    <p className={wBaseLargura < 350 ? "FonteAnuncio_Mob" : "FonteAnuncio"} style={{ marginStart: wUnidadeLargura * 5 }}>{wTexCidKm}</p>
                    {infoAnuncio.linkappmapas == "Sim" && infoAnuncio.cidade_uf.length > 3 ?
                        <IconButton size="small" style={{ marginLeft: wUnidadeLargura * 5, marginTop: wUnidadeLargura * (-3) }}
                            onClick={() => {
                                RegistraClique(null, props.id, "maps");
                                window.open("https://www.google.com.br/maps/search/?api=1&query=" + infoAnuncio.lat + "," + infoAnuncio.lng, "_blank");
                            }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={googlemaps} alt="googlemaps" />
                        </IconButton>
                    :
                        null
                    }
                    {infoAnuncio.tempromo == "Sim" ?
                        <IconButton size="small" style={{ marginLeft: wUnidadeLargura * 8, marginTop: wUnidadeLargura * (-2) }}
                            onClick={() => {
                                props.clicouPromocao();
                            }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={promocaoRed} alt="promocao" />
                        </IconButton>
                    :
                        null
                    }
                </Grid>
                <span> </span>
                <span> </span>
                <Grid item direction="row" style={{ height: '100%' }} xs={1} alignItems="center" justifyContent="flex-start">
                    <IconButton size="small" style={{ marginRight: wUnidadeLargura * 4, marginTop: wUnidadeLargura * (-2) }}
                        onClick={() => {
                            props.clicouFavorito();
                        }}>
                        <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={heart} alt="favoritos" />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container direction="column" xs style={{ width: '100%', height: wBaseLargura * 0.9 / 3 * 2 }} alignItems="center" justifyContent="center">
                <Grid item container direction="column" style={{ width: '100%', height: '100%' }}>
                    {maisInfo || mostraWhats ?
                        <>
                            <Card style={{ width: '100%', height: '100%', borderRadius: 15 }} variant="outlined" onClick={() => { setMaisInfo(false); setMostraWhats(false); }}>
                                <div style={{ width: wBaseLargura * 0.91, height: wBaseLargura * 0.9 / 3 * 2 }}>
                                    <img width="100%" height="100%" src={infoAnuncio.url[indImgBanners]} alt={"banner_" + indImgBanners.toString()} />
                                </div>
                            </Card>
                            {maisInfo ?
                                <Card style={{ marginTop: '-60%', marginLeft: '10%', width: '80%', height: '80%', borderRadius: 15 }} variant="elevation" elevation={4} onClick={() => setMaisInfo(false) }>
                                    <Grid container direction="column" alignItems="center">
                                        <p className={wBaseLargura < 350 ? "FonteTitulos_Mob" : "FonteTitulos"} style={{ marginTop: wUnidadeLargura * 15 }}>Mais informações do Anúncio:</p>
                                        {infoAnuncio.telefone && infoAnuncio.telefone != " "?
                                            <Grid container direction="row" style={{ marginTop: wUnidadeLargura * 10 }} alignItems="center" justifyContent="center">
                                                <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={fone} alt="fone" />
                                                <p className={wBaseLargura < 350 ? "FonteAnuncio_Mob" : "FonteAnuncio"} style={{ marginLeft: 10, marginTop: 5 }}>{infoAnuncio.telefone}</p>
                                            </Grid>
                                        :
                                            null
                                        }
                                    </Grid>
                                    {wAuxDiasSemana !== '' || infoAnuncio.disponibilidade ?
                                        <Grid container direction="column" alignItems="center">
                                            <p className={wBaseLargura < 350 ? "FontePrompts_Mob" : "FontePrompts"} style={{ marginTop: wUnidadeLargura * 7 }}>Atendimento:</p>
                                            <Grid item>
                                                <Grid container direction="column" style={{ marginTop: 1 }} alignItems="center" >
                                                    {wAuxDiasSemana !== '' ?
                                                        <Grid item>
                                                            <p className={wBaseLargura < 350 ? "FontePrompts_Mob" : "FontePrompts"}>{wAuxDiasSemana}</p>
                                                        </Grid>
                                                    :
                                                        null
                                                    }
                                                    {infoAnuncio.disponibilidade ?
                                                        <p className={wBaseLargura < 350 ? "FontePrompts_Mob" : "FontePrompts"}>{infoAnuncio.disponibilidade}</p>
                                                    :
                                                        null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    :
                                        null
                                    }
                                </Card>
                            :
                                <Card style={{ marginTop: '-50%', marginLeft: '20%', width: '60%', height: '40%', borderRadius: 15 }} variant="elevation" elevation={4} onClick={() => setMostraWhats(false)}>
                                    <Grid container direction="row" style={{ marginTop: wUnidadeLargura * 30 }} alignItems="center" justifyContent="center">
                                        <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={whatsappGreen} alt="whatsappGreen" />
                                        <NumberFormat
                                            className={wBaseLargura < 350 ? "FonteAnuncio_Mob" : "FonteAnuncio"}
                                            style={{ marginLeft: 10, marginTop: 5 }}
                                            displayType="text"
                                            value={infoAnuncio.whatsapp.toString().substring(2)}
                                            mask="_"
                                            format={infoAnuncio.whatsapp.toString().substring(4, 5) == "9" && infoAnuncio.whatsapp.toString().length > 12 ? "(##) #####-####" : "(##) ####-####"}
                                        />
                                    </Grid>
                                </Card>
                            }
                        </>
                    :
                        <Card style={{ width: '100%', height: '100%', borderRadius: 15 }} variant="outlined"
                            onDoubleClick={() => {
                                RegistraClique(null, props.id, "maisInfo");
                                mostraMaisInfo();
                            }}>

                            <div style={{ width: wBaseLargura * 0.91, height: wBaseLargura * 0.9 / 3 * 2, position: 'relative', overflow: 'hidden' }}>
                                <SoftCarrossel key={props.ind.toString()} ind={props.ind} vetorImagens={infoAnuncio.url} informaPaiAtuIndAuto={softCrslAtuIndAuto} ref={wRefSoftCrsl} />
                            </div>
                        </Card>
                    }
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ height: wBaseLargura / 40 * 4 }} alignItems="center" justifyContent="flex-start">
                <Grid container direction="row" xs={10} justifyContent="center">
                    <p className={wBaseLargura < 350 ? "FonteAnuncio_Mob" : "FonteAnuncio"} >{infoAnuncio.titulo}</p>
                </Grid>
                <Grid container direction="row" xs={2} justifyContent="flex-end">
                    <IconButton onClick={() => {
                        if (expandido == false) RegistraClique(null, props.id, "expandiu");
                        setExpandido(previousState => !previousState);
                    }}>
                        {expandido ?
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={arrowup} alt="arrowup" />
                        :
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={arrowdown} alt="arrowdown" />
                        }
                    </IconButton>
                </Grid>
            </Grid>
            {expandido ?
                <Grid container direction="column" style={{ height: wBaseLargura / 40 * 18 }} justifyContent="center">
                    <Grid container item style={{ height: wBaseLargura / 40 * 16, overflowY: 'scroll' }}>
                        <Typography variant={wBaseLargura < 350 ? "caption" : "body2"} style={{ lineHeight: 1.2, width: '90%', marginLeft: '5%', color: 'darkgray' }} align="left">{wVetDescricao}</Typography>
                    </Grid>
                </Grid>
            :
                null
            }
            <Grid container direction="row" style={{ height: expandido ? '7.14%' : '10%' }} alignItems="center" justifyContent="flex-start">
                <Grid container direction="row" xs={10} alignItems="center" justifyContent="flex-start">
                    {infoAnuncio.whatsapp && props.whatsapp != " " ?
                        <IconButton size="small" onClick={() => {
                            RegistraClique(null, props.id, "whatsapp");
                            if (isMobile) {
                                window.open('whatsapp://send?text=Olá, encontrei seu anúncio através do iQueest e gostaria de mais informações...&phone=' + infoAnuncio.whatsapp, "_blank");
                            }
                            else {
                                setMostraWhats(true);
                            }
                        }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={whatsapp} alt="whatsapp" />
                        </IconButton>
                    :
                        undefined
                    }
                    {infoAnuncio.instagram && props.instagram != " " ?
                        <IconButton size="small" style={{ marginLeft: 5 }} onClick={() => {
                            RegistraClique(null, props.id, "instagram");
                            window.open("https://www.instagram.com/" + infoAnuncio.instagram, "_blank");
                        }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={instagram} alt="instagram" />
                        </IconButton>
                    :
                        undefined
                    }
                    {infoAnuncio.facebook && props.facebook != " " ?
                        <IconButton size="small" style={{ marginLeft: 5 }} onClick={() => {
                            RegistraClique(null, props.id, "facebook");
                            window.open("https://www.facebook.com/" + infoAnuncio.facebook, "_blank");
                        }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={facebook} alt="facebook" />
                        </IconButton>
                    :
                        undefined
                    }
                    {infoAnuncio.youtube && props.youtube != " " ?
                        <IconButton size="small" style={{ marginLeft: 5 }} onClick={() => {
                            RegistraClique(null, props.id, "youtube");
                            window.open("https://www.youtube.com/channel/" + infoAnuncio.youtube, "_blank");
                        }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={youtube} alt="youtube" />
                        </IconButton>
                    :
                        undefined
                    }
                    {infoAnuncio.twitter && props.twitter != " " ?
                        <IconButton size="small" style={{ marginLeft: 5 }} onClick={() => {
                            RegistraClique(null, props.id, "twitter");
                            window.open("https://www.twitter.com/" + infoAnuncio.twitter, "_blank");
                        }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={twitter} alt="twitter" />
                        </IconButton>
                    :
                        undefined
                    }
                    {infoAnuncio.linkedin && props.linkedin != " " ?
                        <IconButton size="small" style={{ marginLeft: 5 }} onClick={() => {
                            RegistraClique(null, props.id, "linkedin");
                            //window.open("https://www.linkedin.com/company/" + infoAnuncio.linkedin, "_blank");
                            window.open("https://www.linkedin.com/" + infoAnuncio.linkedin, "_blank");
                        }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={linkedin} alt="linkedin" />
                        </IconButton>
                    :
                        undefined
                    }
                    {infoAnuncio.site && props.site != " " ?
                        <IconButton size="small" style={{ marginLeft: 5 }} onClick={() => {
                            RegistraClique(null, props.id, "site");
                            window.open(infoAnuncio.site, "_blank");
                        }}>
                            <img className={wBaseLargura < 350 ? "IconeAnuncio_Mob" : "IconeAnuncio"} src={internet} alt="site" />
                        </IconButton>
                    :
                        undefined
                    }
                </Grid>
            </Grid>
        </Card>
    );
}

export default Anuncio;
