import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { parseISO, format } from "date-fns";

const msgAvisoSqlInjection = 'Dados informados com caracteres inválidos!\nRevise os dados informados.\nNão podem conter os caracteres:   ;  --  #  // /*  */  =';

function PegaLocalizacaoAtual() {
    return new Promise((resolve, reject) => {
        //Location.installWebGeolocationPolyfill();
        //Geolocation.getCurrentPosition((position) => {

        window.parent.navigator.geolocation.getCurrentPosition((position) => {
            resolve([JSON.stringify(position.coords.latitude), JSON.stringify(position.coords.longitude)]);
        }, (error) => {
            window.alert(error.message);
            reject(error)
        },
        {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 10000
        });
    })
}

function BuscaCidadeAtual([lat, lng]) {
    return new Promise(async (resolve, reject) => {
        const requestOptions = { method: 'GET' };
        const response = await fetch('https://www.iqueest.com.br/ws_iqueest/localizacao/cidade?lat=' + lat + '&lng=' + lng, requestOptions);
        if (response.ok == true) {
            resolve(await response.json());
        }
        else {
            window.alert('Não foi possível realizar a busca de informações.');
            reject(response.status.toString());
        }
    })
}

function BuscaDestaques(cidade, lat, lng, idcliente) {
    return new Promise(async (resolve, reject) => {
        const requestOptions = { method: 'GET' };

        const response = await fetch('https://www.iqueest.com.br/ws_iqueest/anuncios/destaques?idcidade=' + cidade +
            (lat.length > 0 && lng.length > 0 ? '&lat=' + lat + '&lng=' + lng : "") +
            (idcliente > 0 ? '&idcliente=' + idcliente : ""), requestOptions);
        if (response.ok == true) {
            resolve(await response.json());
        }
        else {
            window.alert('Não foi possível realizar a busca de informações.');
            reject(response.status.toString());
        }
    })
}

function BuscaCategorias() {
    return new Promise(async (resolve, reject) => {
        const requestOptions = { method: 'GET' };
        const response = await fetch('https://www.iqueest.com.br/ws_iqueest/principal/categorias/', requestOptions);
        if (response.ok == true) {
            resolve(await response.json());
        }
        else {
            window.alert('Não foi possível realizar a busca de informações.');
            reject(response.status.toString());
        }
    })
}

function BuscaAnuncios(idsubcategoria, raio, idcidade, lat, lng, idcliente) {
    return new Promise(async (resolve, reject) => {
        const requestOptions = { method: 'GET' };

        var wAuxUrl = 'https://www.iqueest.com.br/ws_iqueest/anuncios/categorias?idsubcategoria=' + idsubcategoria + '&raio=' + raio;
        if (lat.length > 0 && lng.length > 0) {
            wAuxUrl += '&lat=' + lat + '&lng=' + lng;
        }
        else {
            wAuxUrl += '&idcidade=' + idcidade;
        }

        if (idcliente > 0) {
            wAuxUrl += '&idcliente=' + idcliente;
        }

        const response = await fetch(wAuxUrl, requestOptions);
        if (response.ok == true) {
            resolve(await response.json());
        }
        else {
            window.alert('Não foi possível realizar a busca de informações.');
            reject(response.status.toString());
        }
    })
}

function BuscaAnunciosPesquisa(palavras, raio, idcidade, lat, lng, idcliente) {
    return new Promise(async (resolve, reject) => {
        const requestOptions = { method: 'GET' };

        var wAuxUrl = 'https://www.iqueest.com.br/ws_iqueest/anuncios/pesquisa?palavras=' + palavras + '&raio=' + raio;
        if (lat.length > 0 && lng.length > 0) {
            wAuxUrl += '&lat=' + lat + '&lng=' + lng;
        }
        else {
            wAuxUrl += '&idcidade=' + idcidade;
        }

        if (idcliente > 0) {
            wAuxUrl += '&idcliente=' + idcliente;
        }

        const response = await fetch(wAuxUrl, requestOptions);
        if (response.ok == true) {
            resolve(await response.json());
        }
        else {
            if (response.status == 418) {
                window.alert(msgAvisoSqlInjection);
            }
            else {
                window.alert('Não foi possível realizar a busca de informações.');
            }
            reject(response.status.toString());
        }
    })
}

function BuscaParametros() {
    return new Promise(async (resolve, reject) => {
        const requestOptions = { method: 'GET' };
        const response = await fetch('https://www.iqueest.com.br/ws_iqueest/principal/', requestOptions);
        if (response.ok == true) {
            resolve(await response.json());
        }
        else {
            window.alert('Não foi possível realizar a busca de informações.');
            reject(response.status.toString());
        }
    })
}

function RegistraClique(idcliente, idanuncio, tipo) {
    return new Promise(async (resolve, reject) => {
        var wData = new Date();
        var wAuxData = "";
        var wAuxHora = "";
        var objJson = JSON.parse(`{"cliques": []}`);

        try {
            const wRecuperaCliques = localStorage.getItem('@cliquesRegistrados');

            if (wRecuperaCliques !== null) {
                objJson = JSON.parse(wRecuperaCliques);
            }

            wAuxData = format(wData, 'yyyy-MM-dd');
            wAuxHora = format(wData, 'HH:mm:ss');

            var objClique = new Object();
            objClique.idCliente = idcliente;
            objClique.idAnuncio = idanuncio;
            objClique.tipoClique = tipo;
            objClique.dataClique = wAuxData;
            objClique.horaClique = wAuxHora;

            objJson["cliques"].push(objClique);

            localStorage.setItem('@cliquesRegistrados', JSON.stringify(objJson));

            resolve('sucesso');
        } catch (e) {
            window.alert('Ocorreu um problema ao armazenar informações no cachê do navegador.');
        }
    })
}

function DescarregaCliques() {
    return new Promise(async (resolve, reject) => {
        var objJson = undefined;

        try {
            const wRecuperaCliques = localStorage.getItem('@cliquesRegistrados');

            if (wRecuperaCliques !== null) {
                objJson = JSON.parse(wRecuperaCliques);

                if (objJson["cliques"].length > 0) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(objJson)
                    };

                    var wAuxUrl = 'https://www.iqueest.com.br/ws_iqueest/anuncios/gravacliques';

                    const response = await fetch(wAuxUrl, requestOptions);
                    if (response.ok == true) {
                        localStorage.removeItem('@cliquesRegistrados');
                        resolve(await response.json());
                    }
                    else {
                        window.alert('Não foi possível realizar o envio de informações.');
                        reject(response.status.toString());
                    }
                }
                else {
                    resolve('sucesso');
                }
            }
            else {
                resolve('sucesso')
            }
        } catch (e) {
            window.alert('Ocorreu um problema ao recuperar informações no cachê do navegador.');
            reject('falha')
        }
    })
}

export { PegaLocalizacaoAtual, 
         BuscaCidadeAtual, 
         BuscaDestaques, 
         BuscaCategorias, 
         BuscaAnuncios, 
         BuscaAnunciosPesquisa, 
         BuscaParametros, 
         RegistraClique, 
         DescarregaCliques 
}