import './App.css';
import React, { useState, useEffect, useLayoutEffect, useReducer, useContext, useCallback, useRef } from 'react';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import ButtonBase from '@material-ui/core/ButtonBase';
import { createTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList as List } from 'react-window';
import { isMobile } from 'react-device-detect';

import EscolheCidade from './EscolheCidade.js'
import EscolheLocal from './EscolheLocal.js'

import { BuscaCidadeAtual, BuscaDestaques, BuscaCategorias, BuscaAnuncios, BuscaAnunciosPesquisa, BuscaParametros, DescarregaCliques } from './Requisicoes.js';

import { dadosGlobaisContext } from './ContextGlobal';

import logoSemLupa from './imagens/logo_iqueest_sem_lupa_tr.png';
import lupaBuscando from './imagens/buscando.gif';
import logoiQueest from './imagens/logo_iqueest_oficial_pr.png';
import home from './imagens/home.png';
import homeBlack from './imagens/home-black.png';
import search from './imagens/search.png';
import searchBlack from './imagens/search-black.png';
import search2 from './imagens/search2.png';
import btRaio from './imagens/raio.png';
import promocao from './imagens/promocao.png';
import notification from './imagens/notification.png';
import check from './imagens/check.png';
import location from './imagens/location-black.png';
import aguarde from './imagens/aguarde.gif';

export const anunciosContext = React.createContext();
const categContext = React.createContext();

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#BEBEBE',
        },
        text: {
            primary: '#000000',
            secondary: '#4F4F4F',
            disabled: '#A9A9A9',
            hint: '#F9C205',
        }
    },
    overrides: {
        MuiInputBase: {
            input: {
                '&$disabled': {
                    "-webkit-text-fill-color": '#A9A9A9'
                }
            }
        }
    },
});

const useStyles = makeStyles((theme) => ({
    gridEmForm: {
        marginBottom: '20px',
        alignItems: 'center'
    },
    fonteMiniTextField: {
        fontSize: 10
    },
    fonteMenorTextField: {
        fontSize: 12
    },
    fonteMenorInputLabel: {
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const ISwitch = withStyles({
    switchBase: {
        color: '#f4f3f4',
        '&$checked': {
            color: '#f4f3f4',
        },
        '&$checked + $track': {
            backgroundColor: '#F8961B',
            opacity: 1
        },
    },
    thumb: {
        width: 28,
        height: 28,
        margin: -7
    },
    checked: {},
    track: { backgroundColor: 'gray', opacity: 1, height: 18, width: 45, borderRadius: 12, margin: -4 },
})(Switch);

var wUnidadeLargura = 0;

function Localizacao(props) {
    const [dadosGlobais, dispatchDadosGlobais] = useContext(dadosGlobaisContext);

    const [mensagem, setMensagem] = useState("");
    const [baseLargura, setBaseLargura] = useState(0);
    const [usaRaioBusca, setUsaRaioBusca] = useState(dadosGlobais.localizacao !== "Cidade");
    const [usaLocalizacaoAtual, setUsaLocalizacaoAtual] = useState(dadosGlobais.localizacao === "Atual" || dadosGlobais.localizacao === "Induzida");
    const [escolheCidade, setEscolheCidade] = useState(false);
    const [escolheLocal, setEscolheLocal] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        wUnidadeLargura = baseLargura / 400;
    }, [baseLargura]);

    useEffect(() => {
        if (baseLargura == 0) {
            // BASEADO EM window.innerWidth   >= 1200 (lg)   OU   >= 600 (sm)   OU   >= 0 (xs)
            if (window.innerWidth >= 1200) {
                setBaseLargura(window.innerWidth / 12 * 4);
            }
            else if (window.innerWidth >= 600) {
                setBaseLargura(window.innerWidth / 12 * 8);
            }
            else {
                setBaseLargura(window.innerWidth * 0.98); // USADO 0.98 PORQUE SE USAR 100% DE innerWidth DÁ UM MONTE DE PROBLEMAS EM NAVEGADOR MOBILE
            }
        }
    });

    //useEffect(() => {
    //    if (!usaRaioBusca && dadosGlobais.localizacao !== "Cidade") {
    //        setUsaRaioBusca(true);
    //    }

    //    if (!usaLocalizacaoAtual && dadosGlobais.localizacao !== "Cep" && dadosGlobais.localizacao !== "Logradouro") {
    //        setUsaLocalizacaoAtual(true);
    //    }
    //}, []);

    useLayoutEffect(() => {
        if (!usaRaioBusca && dadosGlobais.localizacao !== "Cidade") {
            // ESCOLHER CIDADE
            setEscolheCidade(true);
        }
    }, [usaRaioBusca, dadosGlobais.localizacao]);

    useLayoutEffect(() => {
        if (usaRaioBusca && !usaLocalizacaoAtual && dadosGlobais.localizacao !== "Cep" && dadosGlobais.localizacao !== "Logradouro") {
            // ESCOLHER LOCAL
            setEscolheLocal(true);
        }
    }, [usaLocalizacaoAtual, dadosGlobais.localizacao]);

    useLayoutEffect(() => {
        if (usaRaioBusca && usaLocalizacaoAtual) {
            if (dadosGlobais.localizacao !== "Atual" && dadosGlobais.localizacao !== "Induzida") {
                buscaDados();
            }
        }
    }, [usaRaioBusca, usaLocalizacaoAtual]);

    async function buscaDados() {
        await dispatchDadosGlobais({ type: 'atuCtrlProcessoAsync', payload: "ATU_LOCAL" });

        var wAuxDataCidadeAtual = [];

        for (let i = 0; i <= 0; i++) {
            if (i == 0) {
                await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition((position) => {
                        dispatchDadosGlobais({ type: 'atuLat', payload: position.coords.latitude.toString() });
                        dispatchDadosGlobais({ type: 'atuLng', payload: position.coords.longitude.toString() });
                        resolve([position.coords.latitude.toString(), position.coords.longitude.toString()]);
                    }, (error) => {
                        setMensagem('Como não conseguimos acesso à sua localização, será preciso escolher uma localização para continuar usando o iQueest.');
                        setUsaLocalizacaoAtual(false);
                    });
                })
                .then(BuscaCidadeAtual)
                .then((rAuxDataCidadeAtual) => wAuxDataCidadeAtual = rAuxDataCidadeAtual)
                .catch((error) => { })
            }
        }

        if (wAuxDataCidadeAtual.length > 0) {
            dispatchDadosGlobais({ type: 'atuAgendaAtuDestaques', payload: true });
            dispatchDadosGlobais({ type: 'atuAtualizaFavoritos', payload: true });
            dispatchDadosGlobais({ type: 'atuIdCidadeAtual', payload: wAuxDataCidadeAtual[0].id });
            dispatchDadosGlobais({ type: 'atuCidadeAtual', payload: wAuxDataCidadeAtual[0].nome });
            dispatchDadosGlobais({ type: 'atuLocalizacao', payload: "Atual" });
        }
        else {
            if (usaLocalizacaoAtual && dadosGlobais.localizacao != "Atual") {
                dispatchDadosGlobais({ type: 'atuIdCidadeAtual', payload: null });
                dispatchDadosGlobais({ type: 'atuCidadeAtual', payload: "" });
                dispatchDadosGlobais({ type: 'atuLocalizacao', payload: "Atual" });
            }
        }

        dispatchDadosGlobais({ type: 'atuCtrlProcessoAsync', payload: "" });
    }

    function informaRetornaEscCid() {
        setEscolheCidade(false);
    }

    function informaRetornaEscLoc() {
        setEscolheLocal(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Grid container item direction="column" style={{ height: window.innerHeight, backgroundColor: '#F5F5F5' }} alignItems="center" justifyContent="flex-start">
                    <Grid container item direction="column" style={{ backgroundColor: '#FFFFFF' }} alignItems="flex-start" justifyContent="flex-start">
                        <Grid container item direction="row" xs={12} style={{ height: '100%', width: baseLargura }} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <img
                                    style={{ height: 56, resizeMode: 'stretch' }}
                                    src={logoiQueest}
                                />
                            </Grid>
                            <Grid item direction="row" alignItems="center" justifyContent="center">
                                <IconButton size="small" style={{ marginRight: 10 }} onClick={() => {
                                    if (dadosGlobais.agendaAtuDestaques == true) {
                                        dispatchDadosGlobais({ type: 'atuAtualizaDestaques', payload: true });
                                        dispatchDadosGlobais({ type: 'atuAgendaAtuDestaques', payload: false });
                                    }
                                    props.retorna();
                                }}>
                                    <img
                                        style={{ width: 22, height: 23, resizeMode: 'stretch' }}
                                        src={homeBlack}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item direction="column" style={{ backgroundColor: '#F5F5F5' }} alignItems="center" justifyContent="flex-start">
                        {escolheCidade ?
                            <EscolheCidade retorna={informaRetornaEscCid} />
                        :
                            escolheLocal ?
                                <EscolheLocal retorna={informaRetornaEscLoc} />
                            :
                                <>
                                    <img
                                        style={{ marginTop: 20, width: 45, height: 46, resizeMode: 'stretch' }}
                                        src={location}
                                    />
                                    <p className="FontePrompts" style={{ marginTop: 40 }}>Indique como o iQueest deve buscar os Anúncios:</p>
                                    <Grid container item direction="row" style={{ marginTop: 20, width: '70%' }} alignItems="center" justifyContent="space-between">
                                        <span className="FontePrompts">Usar raio de busca</span>
                                        <ISwitch size="medium" checked={usaRaioBusca} onChange={() => setUsaRaioBusca((u) => !u)} />
                                    </Grid>
                                    {usaRaioBusca ?
                                        <>
                                            <p className="FontePrompts" style={{ margin: 0, marginTop: 40 }}>Busca por proximidade:</p>

                                            <Grid container item direction="row" style={{ margin: 0, marginTop: 20, width: '70%' }} alignItems="center" justifyContent="space-between">
                                                <span className="FontePrompts">Usar minha localização atual</span>
                                                <ISwitch size="medium" checked={usaLocalizacaoAtual} onChange={() => setUsaLocalizacaoAtual((u) => !u)} />
                                            </Grid>
                                        </>
                                    :
                                        <>
                                            <p style={{ margin: 0, marginTop: 40 }}> Buscar dentro da Cidade: </p>
                                            <ButtonBase onClick={() => { setEscolheCidade(true) }} style={{ margin: 0, marginTop: 20 }} >
                                                <p style={{ margin: 0 }}> {dadosGlobais.cidade} </p>
                                            </ButtonBase>
                                        </>
                                    }
                                    {usaRaioBusca && usaLocalizacaoAtual ?
                                        dadosGlobais.ctrlProcessoAsync == "ATU_LOCAL" ?
                                            <Grid container item direction="row" style={{ width: '76%' }} alignItems="center">
                                                <span style={{ margin: 0, marginTop: 40 }}>Atualizando sua localização atual</span>
                                                <img
                                                    style={{ marginTop: 45, marginLeft: 10, width: 30, height: 30, resizeMode: 'stretch' }}
                                                    src={aguarde}
                                                />
                                            </Grid>
                                        :
                                            null
                                    :
                                        <>
                                            {usaRaioBusca && !usaLocalizacaoAtual ?
                                                <Grid container item direction="column" alignItems="center" justifyContent="flex-start" style={{ width: '100%' }}>
                                                    <p style={{ margin: 0, marginTop: 40 }}> Buscar a partir do Local: </p>

                                                    <ButtonBase onClick={() => setEscolheLocal(true)} style={{ margin: 0, marginTop: 20 }}>
                                                        <p style={{ margin: 0 }}> {dadosGlobais.localizacao == "Logradouro" ? dadosGlobais.cidadeLogra : dadosGlobais.cidadeCep} </p>
                                                    </ButtonBase>

                                                    {dadosGlobais.localizacao == "Cep" ?
                                                        <ButtonBase onClick={() => setEscolheLocal(true)} style={{ margin: 0, marginTop: 20 }}>
                                                            <p style={{ margin: 0 }}> {dadosGlobais.cep} </p>
                                                        </ButtonBase>
                                                    :
                                                        null
                                                    }

                                                    <ButtonBase onClick={() => setEscolheLocal(true)} style={{ margin: 0, marginTop: 20 }}>
                                                        <p style={{ margin: 0 }}> {dadosGlobais.logradouro} </p>
                                                    </ButtonBase>
                                                </Grid>
                                            :
                                                null
                                            }
                                        </>
                                    }
                                </>
                        }
                    </Grid>
                </Grid>
            </div>
            <Dialog open={mensagem.length > 0} fullWidth={true} maxWidth="xs">
                <DialogContent>
                    <DialogContentText>{mensagem}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        //if (mensagem === '') () => algo...;
                        setMensagem("");
                    }} color="primary" style={{ textTransform: 'none' }}>OK</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default Localizacao;
